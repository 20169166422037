body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333338;
  letter-spacing: 0.7px;
}

*{
  box-sizing: border-box;
}

a, a *{
  color: #64b6ac;
  text-decoration: none;
}
a:hover, a:hover *{
  color: #64B6AC;
  text-decoration: underline;
}

hr{
  margin-top: 60px;
  border: 0;
}